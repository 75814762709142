import React, { useEffect, useState } from "react";
import { useContextState } from "./Components/State";
import { Layout } from "antd";
import { Redirect, Route, Switch, withRouter } from "react-router-dom";
import AuthForm from "./Components/AuthForm.js";
import QueryParamsChange from "./Components/QueryParamsChange";
import { Banner, Footer } from "./Components";
import injectSheet from "react-jss";
import config from "./config";
import { SetActiveFlows } from "./State/actions";
import "antd/dist/antd.css";
import "./App.css";

const { Content, Header } = Layout;

function App(props) {
  const { classes: c, history: h } = props;
  const [state, dispatch] = useContextState();
  const params = new URLSearchParams(window.location.search);
  let propCode = params.get("propCode");
  const [activeProperty, setActiveProperty] = useState(propCode ? {} : null);
  const [intervalState, setIntervalState] = useState(undefined);

  async function isUnderMaintenance() {
    try {
      const resp = await fetch(config.maintenanceUrl);
      const { maintenance, url } = await resp.json();
      if (maintenance === true) {
        window.location.href = url;
        return maintenance;
      }
    } catch (err) {}
    return false;
  }

  useEffect(() => {
    isUnderMaintenance();
    if (!intervalState) {
      setIntervalState(setInterval(isUnderMaintenance, 60000));
    }
  });

  async function loadActiveProperty() {
    if (!propCode || (activeProperty && activeProperty.code === propCode)) return;
    let newActiveProperty;
    try {
      newActiveProperty = await fetch(`${config.propertyService}/public/properties/${propCode}`, {
        method: "GET",
        mode: "cors",
        cache: "no-cache",
        credentials: "same-origin",
        headers: {
          "Content-Type": "application/json"
        }
      });
      newActiveProperty = await newActiveProperty.json();
      let { streetAddress, city, state, postalCode } = newActiveProperty;
      newActiveProperty &&
        setActiveProperty({
          ...newActiveProperty,
          address: `${streetAddress}, ${city}, ${state} ${postalCode}`
        });
    } catch (e) {
      console.error("Failed to load active property", e);
    }
  }

  useEffect(() => {
    props.history.listen(location => {
      if (h.action === "POP") {
        let newActiveFlow = h.location.pathname.replace("/", "");
        dispatch(SetActiveFlows([newActiveFlow, h]));
      }
    });
    loadActiveProperty();
  });

  const { bannerState } = state;
  return (
    <Layout style={{ minHeight: "100vh" }}>
      <Banner {...bannerState} />
      <Header className={c.header}>
        <div className="logo_block">
          <a href="/signIn">
            <div className="logo"></div>
          </a>
        </div>
      </Header>
      <QueryParamsChange />
      <Content className={c.page} style={{ padding: 24, minHeight: "100%" }}>
        <Switch>
          <Route
            key={"/signIn"}
            path={"/signIn"}
            render={() => (
              <AuthForm
                history={h}
                activeProperty={activeProperty}
                activeFlow={"signIn"}
                showAlert={activeProperty}
              />
            )}
            exact
          />
          <Route
            key={"/accountActionRequired"}
            path={"/register/actionRequired"}
            render={() => (
              <AuthForm
                history={h}
                activeProperty={activeProperty}
                activeFlow="accountActionRequired"
              />
            )}
            exact
          />
          <Route
            key={"/passwordActionRequired"}
            path={"/resetPassword/actionRequired"}
            render={() => (
              <AuthForm
                history={h}
                activeProperty={activeProperty}
                activeFlow="resetPassword/actionRequired"
              />
            )}
            exact
          />
          <Route
            key={"/resetPassword"}
            path={"/resetPassword"}
            render={() => (
              <AuthForm history={h} activeProperty={activeProperty} activeFlow="resetPassword" />
            )}
            exact
          />
          <Route
            key={"/newPassword"}
            path={"/newPassword"}
            render={() => (
              <AuthForm history={h} activeProperty={activeProperty} activeFlow="newPassword" />
            )}
          />
          <Route
            key={"/confirm"}
            path={"/confirmAccount"}
            render={() => (
              <AuthForm history={h} activeProperty={activeProperty} activeFlow={"confirmAccount"} />
            )}
            exact
          />
          <Route
            key={"/register"}
            path={"/register"}
            render={() => (
              <AuthForm history={h} activeProperty={activeProperty} activeFlow={"register"} />
            )}
          />
          <Redirect
            to={{
              pathname: "/signIn",
              search: window.location.search
            }}
          />
        </Switch>
      </Content>
      <Footer classes={c.footer} />
    </Layout>
  );
}

const css = {
  header: {
    backgroundColor: "#ffffff",
    "@media screen and (max-width: 400px)": {
      display: "none"
    },
    padding: 0,
    "& .logo_block": {
      marginTop: "30px",
      marginLeft: "250px",
      "& .logo": {
        display: "inline-block",
        width: "100px",
        height: "26px",
        backgroundImage: "url('/images/logo-large.svg')",
        backgroundRepeat: "no-repeat"
      }
    }
  },
  page: {
    backgroundColor: "#ffffff",
    paddingTop: "54px"
  }
};

export default withRouter(injectSheet(css)(App));
