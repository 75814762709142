import { Button, Form } from "antd";
import injectSheet from "react-jss";
import { useContextState } from "../Components/State";
import { EXCEPTION_CODE } from "../Constants/ErrorCodes";
import { every, values, isEmpty } from "lodash";
import { SetActiveFlows, SetCognitoDetails, SetBannerState, ShowSpinner } from "../State/actions";
import React, { useState, useContext, useEffect } from "react";
import { CognitoContext } from "./Authenticator";
import configurations from "../views";
import CognitoFlow from "../Constants/CognitoFlowState";
import Spinner from "./Spinner";
const { USER_NOT_FOUND, INVALID_PARAMETER, NOT_AUTHORIZED } = EXCEPTION_CODE;

const AuthForm = props => {
  const { classes: c, form, activeProperty, history, showAlert } = props;
  const { getFieldDecorator, getFieldValue } = form;
  const [state, dispatch] = useContextState();
  if (!state.activeFlow) dispatch(SetActiveFlows(props.activeFlow));
  let cognito = useContext(CognitoContext);
  const urlParams = new URLSearchParams(window.location.search);

  let [activeConfiguration, setActiveConfiguration] = useState({
    fields: null,
    handleSubmit: () => {}
  });
  let [authData, setAuthData] = useState(cognito);
  let [formValid, setFormValid] = useState(true);

  useEffect(() => {
    setActiveConfiguration(configurations[state.activeFlow]);

    const form = props.form;
    const errors = values(form.getFieldsError());
    let hasNoFormErrors = every(errors, error => isEmpty(error));

    let requiredFields = [];
    switch (state.activeFlow) {
      case CognitoFlow.SIGN_IN:
        requiredFields = ["email", "password"];
        break;
      case CognitoFlow.SIGN_UP:
        requiredFields = activeProperty
          ? ["given_name", "family_name", "email", "phone_number"]
          : ["given_name", "family_name", "email", "phone_number", "property_id"];
        break;
      case CognitoFlow.RESET_PASSWORD:
        requiredFields = ["email"];
        break;
      case CognitoFlow.NEW_PASSWORD:
        requiredFields = ["password", "confirmPassword"];
        break;
    }
    hasNoFormErrors &= requiredFields.every(fieldName => form.getFieldValue(fieldName));
    setFormValid(hasNoFormErrors);
  }, [state.activeFlow, props]);

  function renderPropertyDetails() {
    if (!activeProperty || (activeConfiguration && activeConfiguration.hidePropData)) return null;

    return (
      <aside role="region" className="">
        <p className="company-name">{activeProperty.companyName}</p>
        <p className="property-name">{activeProperty.name}</p>
        <p className="property-address">{activeProperty.address}</p>
        {urlParams.get("flow") !== "VUA" && (
          <small>
            Wrong property? <a href={`/register`}>Click here to change</a>
          </small>
        )}
      </aside>
    );
  }

  function renderSubHeaderContent() {
    if (!activeConfiguration) return null;
    const { subheaderContent } = activeConfiguration;
    switch (typeof subheaderContent) {
      case "undefined":
        return null;
      case "function":
        return subheaderContent(state);
      default:
        return subheaderContent;
    }
  }

  function renderSubHeaderAlertContent() {
    if (!activeConfiguration) return null;
    const { subheaderAlertContent } = activeConfiguration;
    switch (typeof subheaderAlertContent) {
      case "undefined":
        return null;
      case "function":
        return subheaderAlertContent(state);
      default:
        return subheaderAlertContent;
    }
  }

  function renderFooterText() {
    if (!activeConfiguration) return null;
    let text;
    const { footerText } = activeConfiguration;
    text = (() => {
      switch (typeof footerText) {
        case "undefined":
          return null;
        case "function":
          return footerText(state);
        default:
          return footerText;
      }
    })();
    return text && <p className={c.footerText}>{renderFooterText()}</p>;
  }

  function renderActions() {
    if (!activeConfiguration || activeConfiguration.hideActions) {
      return null;
    } else if (typeof activeConfiguration.renderActions === "function") {
      return activeConfiguration.renderActions(
        activeProperty,
        dispatch,
        props.history,
        formValid,
        state
      );
    } else if (Boolean(activeConfiguration.buttonRow)) {
      return activeConfiguration.buttonRow;
    } else if (Boolean(activeConfiguration.buttonText)) {
      return (
        <Button
          type="primary"
          htmlType="submit"
          loading={state.fetching}
          disabled={state.fetching || !formValid}
        >
          {activeConfiguration.buttonText}
          {renderButtonIcon()}
        </Button>
      );
    } else {
      return (
        <Button type="primary" htmlType="submit" loading={state.fetching} disabled={state.fetching}>
          Submit
        </Button>
      );
    }
  }

  function renderButtonIcon() {
    if (!activeConfiguration.buttonIcon) return null;
    return (
      <i
        className="material-icons mdc-text-field__icon mdc-text-field__icon--leading"
        role="button"
      >
        {activeConfiguration.buttonIcon}
      </i>
    );
  }

  function renderFields() {
    if (!activeConfiguration || !activeConfiguration.fields) return null;
    let localProps = {
      c,
      activeProperty,
      getFieldDecorator,
      getFieldValue,
      dispatch,
      history,
      form
    };
    return activeConfiguration.fields(localProps);
  }

  const subheaderContent = renderSubHeaderContent();
  const subheaderAlertContent = renderSubHeaderAlertContent();
  let { headerText } = activeConfiguration || {};

  return (
    <div
      className={`${state.activeFlow || ""} ${activeProperty ? "" : "no-frills"} ${c.authWrapper}`}
    >
      <div className="formContainer">
        <Form hideRequiredMark className={`${c.authForm} authForm`} onSubmit={onSubmit}>
          <Spinner show={state.fetching} />
          <header className={c.row}>
            <img src="/images/logo-d.svg" className="logo" />

            <h1 col={24}>{headerText}</h1>

            {renderPropertyDetails()}

            {showAlert && subheaderAlertContent !== null && (
              <summary col={24} className={`${state.activeFlow}--alert`}>
                <div dangerouslySetInnerHTML={{ __html: subheaderAlertContent }} />
              </summary>
            )}

            {subheaderContent !== null && (
              <summary col={24} className={`${state.activeFlow}--summary`}>
                <div dangerouslySetInnerHTML={{ __html: subheaderContent }} />
              </summary>
            )}
          </header>
          <div className="formContentWrap">
            {renderFields()}
            <div className={`${state.activeFlow} ${c.buttonRow}`}>{renderActions()}</div>
          </div>
          {renderFooterText()}
        </Form>
      </div>
    </div>
  );

  async function onSubmit(e) {
    e.preventDefault();
    const form = props.form;
    form.validateFields(async (error, values) => {
      if (error) {
        return;
      }

      let payload = {};
      let shouldProceed = true;
      const {
        CONFIRM_ACCOUNT,
        RESET_PASSWORD,
        VERIFY_CONFIRMATION_CODE,
        NEW_PASSWORD,
        SIGN_IN,
        ACCOUNT_ACTION_REQUIRED,
        SIGN_UP,
        PASSWORD_ACTION_REQUIRED
      } = CognitoFlow;

      switch (state.activeFlow) {
        case RESET_PASSWORD:
          payload = { email: form.getFieldValue("email") };
          dispatch(SetCognitoDetails({ email: form.getFieldValue("email") }));
          break;
        case VERIFY_CONFIRMATION_CODE:
          dispatch(SetCognitoDetails({ code: form.getFieldValue("code") }));
          break;
        case NEW_PASSWORD:
          const password =
            form.getFieldValue("password") === form.getFieldValue("confirmPassword")
              ? form.getFieldValue("password")
              : "";
          payload = {
            newPassword: password
          };
          dispatch(SetCognitoDetails({ password }));
          break;
        case SIGN_IN:
          payload = {
            clientMetadata: {
              preferred_username: form.getFieldValue("email").trim()
            },
            email: form.getFieldValue("email").trim(),
            password: form.getFieldValue("password")
          };
          break;
        case CONFIRM_ACCOUNT:
          let email = state.cognito && state.cognito.email;
          const first_name = form.getFieldValue("first_name");
          const last_name = form.getFieldValue("last_name");
          const isManager = Boolean(first_name && last_name);
          payload = {
            email: email && email.trim(),
            newPassword: form.getFieldValue("password"),
            code: form.getFieldValue("code"),
            attributes: {
              isManager,
              first_name,
              last_name,
              agreement_terms: form.getFieldValue("agreement_terms"),
              agreement_privacy: form.getFieldValue("agreement_privacy")
            }
          };
          if (!payload.attributes.agreement_terms) {
            form.setFields({
              agreement_privacy: {
                value: form.values && form.values.agreement_terms,
                errors: [{ message: "Required" }]
              }
            });
          } else dispatch(SetCognitoDetails(payload));
          break;
        case SIGN_UP:
          payload = {
            clientMetadata: {
              prop_id: form.getFieldValue("property_id") || activeProperty.id.toString()
            },
            username: form.getFieldValue("email").trim(),
            email: form.getFieldValue("email").trim(),
            given_name: form.getFieldValue("given_name"),
            family_name: form.getFieldValue("family_name"),
            phone_number: form.getFieldValue("phone_number")
          };
          dispatch(SetCognitoDetails(payload));
          break;
        case ACCOUNT_ACTION_REQUIRED:
          email = state.cognito && state.cognito.email;
          payload = {
            email: email
          };
          break;
        case PASSWORD_ACTION_REQUIRED:
          payload = {
            email: state.cognito && state.cognito.email
          };
          break;
        default:
          break;
      }

      let { handleSubmit, surfaceErrors, onSuccess, failFlow, nextFlow } = activeConfiguration;
      handleSubmit = handleSubmit.bind(cognito);
      dispatch(ShowSpinner(true));
      await handleSubmit(payload, dispatch, form)
        .then(res => {
          if (onSuccess) {
            let successMessage =
              typeof onSuccess === "function" ? onSuccess({ ...payload, ...res }) : onSuccess;
            setAuthData(Object.assign(authData, res));
            if (nextFlow) {
              dispatch(SetActiveFlows([nextFlow, props.history]));
            }
            dispatch(
              SetBannerState({
                type: "success",
                text: successMessage,
                show: successMessage
              })
            );
          }
          dispatch(ShowSpinner(false));
          return res;
        })
        .catch(e => {
          shouldProceed = false;
          if (e.name === "SilentFailure") {
            dispatch(ShowSpinner(false));
            return;
          }

          let errMessage =
            surfaceErrors &&
            e.message !== "Cannot reset nonexistent password" &&
            !e.message.includes("shared email") &&
            !e.message.includes("UserMigration failed with error Incorrect username or password.")
              ? surfaceErrors.get(e.name) || e.message
              : e.message;
          if (failFlow) {
            const qs =
              activeProperty && activeProperty.code ? `?propCode=${activeProperty.code}` : "";
            dispatch(SetActiveFlows(failFlow, props.history));
            props.history.push(`/${failFlow}${qs}`);
          }

          const isAuthError = [INVALID_PARAMETER].includes(e.code);

          dispatch(
            SetBannerState({
              type: "error",
              text: errMessage || e.message,
              show: !isAuthError
            })
          );

          if (isAuthError) {
            props.form.setFields({
              email: {
                value: props.form.getFieldValue("email"),
                errors: [new Error(errMessage)]
              },
              password: {
                value: props.form.getFieldValue("password"),
                errors: [new Error(errMessage)]
              }
            });
          }
        });
    });
  }
};

const css = {
  authWrapper: {
    "& a": {
      color: "#077DFF"
    },
    margin: "0 auto 0 auto",
    maxWidth: "500px",
    minWidth: "20%",
    display: "flex",
    flexFlow: "column",
    justifyContent: "center",
    "@media screen and (max-width: 400px)": {
      margin: "30px auto"
    },
    "& .action-link": {
      marginTop: "30px",
      fontSize: "14px",
      fontWeight: 600,
      color: "#0B1336"
    },
    "& .password-visibility": {
      cursor: "pointer",
      fontSize: "14px"
    },
    "& .header-content-list": {
      display: "inline-block",
      textAlign: "left",
      margin: "0 auto"
    },
    "& aside": {
      "-moz-box-sizing": "border-box",
      "-webkit-box-sizing": "border-box",
      alignSelf: "center",
      margin: "18px auto 34px !important",
      boxSizing: "border-box",
      display: "flex",
      flexDirection: "column",
      lineHeight: "1.4",
      textAlign: "left",
      minWidth: "25%",
      maxWidth: "275px",
      "&.narrow": { marginBottom: "-10px !important" },
      "& small": { marginTop: "8px", color: "#474747" },
      "& > p": {
        fontSize: "14px",
        margin: "2px 0",
        color: "#474747",
        fontWeight: "400"
      },
      "& .property-name": {
        color: "#0B1336",
        fontWeight: "600"
      },
      "& .company-name": {
        fontSize: "12px",
        lineHeight: "1.4",
        fontWeight: "600"
      },
      "& .requirements": {
        textAlign: "center",
        fontWeight: "600",
        lineHeight: "21px",
        marginTop: "-40px"
      }
    },
    "&.register": { minWidth: "40%" },
    "&.signIn, .resetPassword": {
      minWidth: "40%",
      maxWidth: "650px",
      "@media screen and (max-width: 720px)": {
        maxWidth: "400px"
      },
      "&.no-frills": {
        maxWidth: "400px",
        minWidth: "220px",
        alignItems: "center",
        "@media screen and (max-width: 400px)": {
          margin: "30px auto"
        },
        "& .authForm": {
          width: "100%",
          "@media screen and (max-width: 720px)": {
            width: "100%"
          },
          "@media screen and (max-width: 400px)": {
            width: "100%"
          }
        },
        "& figure": { display: "none" }
      },
      "& .formContainer": {
        display: "flex",
        alignItems: "flex-end",
        flexDirection: "row",
        "@media screen and (max-width: 720px)": {
          flexDirection: "column",
          alignItems: "center"
        }
      },
      "& .authForm": {
        width: "60%",
        "@media screen and (max-width: 720px)": {
          width: "100%"
        },
        "@media screen and (max-width: 400px)": {
          width: "100%"
        }
      },
      "& figure": {
        display: "flex",
        paddingLeft: "20px",
        marginBottom: "5px",
        width: "40%",
        justifyContent: "center",
        "& img": {
          width: "100%",
          "@media screen and (max-width: 720px)": {
            width: "230px"
          },
          "@media screen and (max-width: 400px)": {
            width: "200px",
            maxWidth: "100%"
          }
        },
        "@media screen and (max-width: 720px)": {
          width: "240px",
          maxWidth: "100%",
          marginTop: "18px",
          paddingLeft: "0"
        }
      }
    },
    "& figure": { display: "none" },
    "&.resetPassword button": {
      marginTop: "0px"
    },
    "& > div": {
      display: "flex",
      flexFlow: "row",
      alignItems: "flex-end",
      width: "100%"
    }
  },
  authForm: {
    flexGrow: 1,
    width: "100%",
    "& .ant-select-auto-complete": {
      "& .ant-input": { border: "1px solid rgba(0, 0, 0, 0.42)" }
    },

    "& fieldset": {
      "& > a": { display: "block" },
      "& > .helpText": { fontSize: "12px" },
      "&.register-fieldset": {
        "& > div": {
          marginTop: "5px"
        }
      }
    },
    "& .MuiFormLabel-root": {
      fontSize: "12px",
      fontFamily: '"proxima-nova", serif',
      fontWeight: "400",
      color: "#757575 !important"
    },
    "& .Mui-focused.MuiFormLabel-root, & .MuiFormLabel-filled": {
      color: "#0B1336 !important",
      fontWeight: "600"
    },
    "& summary p": { wordBreak: "break-word" },
    "& header": {
      flexDirection: "column",
      textAlign: "center",
      "& .logo": {
        width: "50px",
        height: "50px",
        display: "block",
        margin: "0 auto"
      },
      "& > h1": {
        fontWeight: "600",
        fontSize: "32px",
        marginTop: "5px",
        color: "#0B1336"
      },
      "& > summary": {
        color: "#0B1336",
        fontSize: "14px",
        marginTop: "30px",
        fontWeight: "400"
      },
      "& > summary.signIn--summary": {
        fontSize: "18px"
      },
      "& > summary.signIn--alert": {
        fontSize: "18px",
        fontWeight: "bold"
      },
      "& > *": {
        margin: 0,
        padding: 0
      }
    },
    "& .formContentWrap": {
      maxWidth: "275px",
      margin: "0 auto",
      "& .ant-form-item": {
        marginTop: "56px"
      },
      "& .ant-form-item.checkbox-item": {
        marginTop: 18
      },
      "& .ant-form-item.checkbox-item.checkboxGroup": {
        marginTop: 95
      },
      "& summary.action-required": {
        textAlign: "center",
        marginLeft: "-50px",
        marginRight: "-50px",
        "&.button-hint": {
          marginTop: "120px",
          marginBottom: "25px"
        },
        "&.link": {
          textAlign: "center",
          fontWeight: "600",
          marginTop: "40px",
          "& > a": {
            color: "#0B1336"
          }
        }
      },
      "@media screen and (max-width: 450px)": {
        "& summary.action-required": {
          marginLeft: "0",
          marginRight: "0"
        }
      }
    },
    "& .ant-form-item-control": {
      lineHeight: 0,
      marginTop: "5px"
    },
    "& .MuiFormControl-root": {
      width: "100%"
    },
    "& .MuiInput-underline": {
      marginTop: "5px"
    },
    "& .MuiInput-underline:hover:before, & .MuiInput-underline:after": {
      borderColor: "#EAA945 !important"
    },
    "& .MuiInputBase-input:focus": {
      backgroundColor: "#FFFDE0"
    },
    "& .has-error": {
      "& .MuiInput-underline:hover:before, .MuiInput-underline:before, & .MuiInput-underline:after": {
        borderColor: "#ED111C !important"
      },
      "& .Mui-focused.MuiFormLabel-root, & .MuiFormLabel-filled": {
        color: "#ED111C !important"
      },
      "& .ant-form-explain": {
        color: "#ED111C"
      }
    },
    "& .ant-form-explain": {
      marginTop: "5px",
      fontSize: "12px"
    },
    "& .ant-form-item": {
      flexGrow: "1",
      marginBottom: "5px",
      flexBasis: "169px",
      "& .ant-checkbox-wrapper": {
        color: "#000",
        display: "flex",
        lineHeight: "normal !important",
        fontSize: ".8rem"
      }
    },
    "& .ant-checkbox": {
      height: 16
    }
  },
  buttonRow: {
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    flexGrow: "1",
    marginTop: "80px",
    padding: "5px 0px",
    "& p": { fontWeight: "bold", margin: "16px 0", color: "#0B1336" },
    "& a": { fontSize: "1em", marginTop: "5px" },
    "& .ant-btn-primary, & .ant-btn-default": {
      borderColor: "",
      border: "none",
      width: "100%",
      borderRadius: "32px",
      color: "#0B1336"
    },
    "& .ant-btn": {
      paddingTop: "12px",
      paddingBottom: "12px",
      fontWeight: "600",
      height: "auto",
      lineHeight: "17px",
      "& > span": {
        verticalAlign: "middle"
      },
      "& > .material-icons": {
        verticalAlign: "middle",
        height: "14px",
        marginLeft: "10px",
        marginTop: "-8px"
      }
    },
    "& .ant-btn-primary": {
      backgroundColor: "#EAA945",
      "&:hover": {
        backgroundColor: "#F1BB67"
      },
      "&:focus": {
        backgroundColor: "#EAA945",
        filter: "drop-shadow(0px 6px 12px rgba(255, 163, 57, 0.55))"
      },
      "&:disabled": {
        backgroundColor: "#E5E5E5",
        color: "#47547E"
      }
    },
    "& .ant-btn-default": {
      border: "1px solid #0B1336",
      backgroundColor: "#FFFFFF",
      color: "#0B1336",
      "&:hover": {
        backgroundColor: "#F5F9FF",
        color: "#233363"
      },
      "&:focus": {
        backgroundColor: "#E6F0FA",
        borderColor: "#29396B",
        filter: "drop-shadow(0px 6px 12px rgba(55, 59, 146, 0.2))"
      },
      "&:disabled": {
        color: "#9E9E9E",
        borderColor: "#C2C2C2",
        backgroundColor: "#FFFFFF"
      }
    }
  },
  footerText: {
    fontSize: ".75rem"
  },
  row: {
    display: "flex",
    margin: "0 auto",
    width: "100%",
    "&.group": {
      justifyContent: "space-between",
      "& > *": {
        width: "50%",
        marginRight: "8px",
        "&:last-child": {
          marginRight: "0"
        }
      },
      "@media screen and (max-width: 1000px)": {
        "&": {
          display: "block"
        },
        "& > *": {
          width: "100%",
          marginRight: "0"
        }
      }
    },
    "&:nth-last-child(2)-type *": { fontSize: ".98rem" },
    "& .subheader-email": {
      fontWeight: 600
    }
  },
  col: {},
  spacer: {
    "@media screen and (max-width: 450px)": {
      display: "none"
    }
  }
};
export default Form.create({ name: "register" })(injectSheet(css)(AuthForm));
