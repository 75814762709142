import React, { useEffect } from "react";
import { withRouter } from "react-router";
import qs from "qs";
import { getErrorMessageByCode } from "../Constants/ErrorCodes";
import { SetBannerState } from "../State/actions";
import { useContextState } from "./State";

const QueryParamsChange = props => {
  const [_, dispatch] = useContextState();

  useEffect(() => {
    const query = qs.parse(props.location.search, { ignoreQueryPrefix: true });
    if (query && query.error_code) {
      dispatch(
        SetBannerState({
          type: "error",
          show: true,
          text: getErrorMessageByCode(query.error_code)
        })
      );
    }
  }, [props.location.pathname, props.location.search]);

  return null;
};

export default withRouter(QueryParamsChange);
