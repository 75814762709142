import React from "react";
import { Spin } from "antd";

export default function Spinner(props) {
  const defaultStyle = { textAlign: "center" };
  const style = { ...defaultStyle, ...props.style, display: props.show ? "block" : "none" };

  return (
    <div style={style}>
      <Spin tip={props.text || null} />
    </div>
  );
}
