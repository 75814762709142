import React from "react";
import injectSheet from "react-jss";
import config from "../config";

function Footer(props) {
  return (
    <footer className={`footer dark long-footer ${props.classes.footer}`}>
      <ul className="f-links">
        <li>
          <a href="https://domusoinc.zendesk.com/hc/en-us" target="_blank">
            FAQ
          </a>
        </li>
        <li>
          <a href="https://domuso.com/legal/" target="_blank">
            Legal
          </a>
        </li>
        <li>
          <a href="https://domuso.com/privacy/" target="_blank">
            Privacy
          </a>
        </li>
      </ul>
      <div className="copy">© {new Date().getFullYear()} Domuso, Inc.</div>
    </footer>
  );
}

const css = {
  footer: {
    width: "100%",
    color: "#0B1336",
    backgroundColor: "#fff",
    position: "relative",
    bottom: 0,
    padding: "1rem",
    alignItems: "center",
    display: "flex",
    flexFlow: "column",
    textAlign: "center",
    zIndex: "2",
    "& a": {
      color: "#0B1336"
    },
    "& ul": {
      position: "relative",
      left: "auto",
      right: "auto",
      bottom: "auto",
      padding: "1rem 0 0",
      textAlign: "center",
      marginBottom: "15xp",
      "&.f-cont li": { display: "block" },
      "& li": {
        display: "inline-block",
        margin: "0 5px",
        position: "relative"
      }
    }
  }
};

export default injectSheet(css)(Footer);
