import { ActionTypes } from "./actions";
import configurations from "../views";
import ReactDOM from "react-dom";

const reducer = (state, action) => {
  switch (action.type) {
    case ActionTypes.SET_ACTIVE_FLOW: {
      let [payload, history] = Array.isArray(action.payload)
        ? action.payload
        : [action.payload, null];
      if (history) {
        history.push(`/${payload}${history.location.search || window.location.search}`);
        payload = configurations.keys[payload];
      }
      if (state.activeFlow) {
        state.prevFlow = state.activeFlow;
        try {
          let [target] = document.getElementsByClassName(state.activeFlow);
          target && ReactDOM.unmountComponentAtNode(target);
        } catch (e) {
          // eat compatibility errors
        }
      }

      return {
        ...state,
        activeFlow: payload,
        activeConfiguration: configurations[payload],
        bannerState: { show: false }
      };
    }
    case ActionTypes.SET_BANNER_STATE: {
      if (action.payload.show) {
        window.scrollTo(0, 0);
      }
      return {
        ...state,
        fetching: false,
        bannerState: {
          ...state.bannerState,
          ...action.payload
        }
      };
    }
    case ActionTypes.SET_COGNITO_DETAILS: {
      return {
        ...state,
        cognito: {
          ...state.cognito,
          ...action.payload
        }
      };
    }
    case ActionTypes.SET_SPINNER_STATE: {
      return {
        ...state,
        fetching: action.payload
      };
    }
    default:
      return state;
  }
};

export default reducer;
