import React, { useState } from "react";
import { Checkbox, Form } from "antd";
import { PasswordValidator } from "./ConfirmAccount";

import { FormControl, Input, InputAdornment, InputLabel, TextField } from "@material-ui/core";
import config from "../../config";

const NewPasswordFieldset = props => {
  let [showPassword, setShowPassword] = useState(false);

  const { c, getFieldDecorator, getFieldValue } = props;

  return (
    <fieldset>
      <div className={c.row}>
        <Form.Item colon={false}>
          {getFieldDecorator("password", {
            validateFirst: true,
            rules: [
              { required: true, message: "Password is required" },
              {
                validator: PasswordValidator
              }
            ]
          })(
            <FormControl>
              <InputLabel htmlFor={`password`}>Create password</InputLabel>
              <Input
                id={`password`}
                type={showPassword ? "text" : "password"}
                endAdornment={
                  <InputAdornment position="end">
                    <span
                      className={`password-visibility`}
                      onClick={() => setShowPassword(!showPassword)}
                    >
                      {showPassword ? "Hide" : "Show"}
                    </span>
                  </InputAdornment>
                }
              />
            </FormControl>
          )}
        </Form.Item>
      </div>
      <div className={c.row}>
        <Form.Item colon={false}>
          {getFieldDecorator("confirmPassword", {
            rules: [
              { required: true, message: "Confirm password is required" },
              {
                validator: async (rule, value) => {
                  if (!value || getFieldValue("password") === value) {
                    return Promise.resolve();
                  }

                  return Promise.reject("Passwords do not match");
                }
              }
            ]
          })(
            <FormControl>
              <InputLabel htmlFor={`confirmPassword`}>Confirm password</InputLabel>
              <Input
                id={`confirmPassword`}
                type={showPassword ? "text" : "password"}
                endAdornment={
                  <InputAdornment position="end">
                    <span
                      className={`password-visibility`}
                      onClick={() => setShowPassword(!showPassword)}
                    >
                      {showPassword ? "Hide" : "Show"}
                    </span>
                  </InputAdornment>
                }
              />
            </FormControl>
          )}
        </Form.Item>
      </div>
      <div className={c.row}>
        <Form.Item className={"checkboxGroup checkbox-item"}>
          {getFieldDecorator("agreement_terms", {
            valuePropName: "checked",
            rules: [
              {
                validator: async (rule, value) =>
                  !!value ? Promise.resolve() : Promise.reject("Required")
              }
            ]
          })(
            <Checkbox>
              I have read the{" "}
              <a target="_blank" href={`https://domuso.com/legal/`}>
                Terms and Conditions
              </a>
            </Checkbox>
          )}
        </Form.Item>
      </div>
      <div className={c.row}>
        <Form.Item className={"checkbox-item"}>
          {getFieldDecorator("agreement_privacy", {
            valuePropName: "checked"
          })(
            <Checkbox>
              I agree to the{" "}
              <a target="_blank" href={`${config.emailLinkBase}/statics/tcpa`}>
                TCPA Consent & Privacy policy
              </a>{" "}
              (Optional)
            </Checkbox>
          )}
        </Form.Item>
      </div>
    </fieldset>
  );
};

export default NewPasswordFieldset;
