import { Checkbox, Form } from "antd";
import React, { useContext, useState, useEffect } from "react";
import { Redirect } from "react-router-dom";
import config from "../../config.js";
import { SetActiveFlows, SetBannerState, ShowSpinner } from "../../State/actions";
import { CognitoContext } from "../Authenticator";
import { TextField } from "@material-ui/core";

export const PasswordValidator = (rule, value) => {
  const rules = [/(?=.{8,})/];
  const isValidPassword = rules.every(r => r.test(value));
  if (!isValidPassword) return Promise.reject("Please enter at least 8 characters");
  return Promise.resolve();
};

const ConfirmAccountFieldset = props => {
  const { c, getFieldDecorator, getFieldValue, dispatch, form } = props;
  let cognito = useContext(CognitoContext);
  let [user, setUser] = useState(cognito.user);
  const params = new URLSearchParams(window.location.search);
  const token = params.get("t");
  const userId = params.get("userId");
  const username = params.get("username");
  const code = params.get("cvc");
  const flow = params.get("flow");
  const canChangeFNameLName = params.get("changeFirstNameLastName");

  useEffect(() => {
    if (!cognito.user) confirmUserIdentity(userId || username || token);
  }, [userId, token, cognito]);

  if (!token || !code) {
    dispatch(SetActiveFlows("register"));
    return <Redirect to="./register" />;
  }

  const renderFNameLNameFields = () => (
    <>
      <div className={c.row}>
        <Form.Item colon={false}>
          {getFieldDecorator("first_name", {
            validateFirst: true,
            rules: [
              { required: true, message: "First name is required." },
              {
                validator: async (_, value) => {
                  return String(value).trim().length === 0
                    ? Promise.reject("First name is required.")
                    : Promise.resolve();
                }
              }
            ]
          })(<TextField label="First Name" />)}
        </Form.Item>
      </div>
      <div className={c.row}>
        <Form.Item colon={false}>
          {getFieldDecorator("last_name", {
            validateFirst: true,
            rules: [
              { required: true, message: "Last name is required." },
              {
                validator: async (_, value) => {
                  return String(value).trim().length === 0
                    ? Promise.reject("Last name is required.")
                    : Promise.resolve();
                }
              }
            ]
          })(<TextField label="Last Name" />)}
        </Form.Item>
      </div>
    </>
  );

  return (
    <fieldset>
      {canChangeFNameLName && renderFNameLNameFields()}
      <div className={c.row}>
        <Form.Item colon={false}>
          {getFieldDecorator("password", {
            validateFirst: true,
            rules: [
              {
                validator: PasswordValidator
              }
            ]
          })(<TextField type="password" label="Password" />)}
        </Form.Item>
      </div>
      <div className={c.row}>
        <Form.Item colon={false}>
          {getFieldDecorator("confirmPassword", {
            rules: [
              { required: true, message: "Confirm password is required" },
              {
                validator: async (rule, value) => {
                  if (!value || getFieldValue("password") === value) {
                    return Promise.resolve();
                  }

                  return Promise.reject("Passwords do not match");
                }
              }
            ]
          })(<TextField type="password" label="Confirm Password" />)}
        </Form.Item>
      </div>
      <div className={c.row}>
        <Form.Item className={"checkboxGroup checkbox-item"}>
          {getFieldDecorator("agreement_terms", {
            valuePropName: "checked",
            rules: [
              {
                validator: async (rule, value) =>
                  !!value ? Promise.resolve() : Promise.reject("Required")
              }
            ]
          })(
            <Checkbox>
              I have read the{" "}
              <a target="_blank" href={`https://domuso.com/legal/`}>
                Terms and Conditions
              </a>
            </Checkbox>
          )}
        </Form.Item>
      </div>
      <div className={c.row}>
        <Form.Item className={`checkbox-item`}>
          {getFieldDecorator("agreement_privacy", {
            valuePropName: "checked"
          })(
            <Checkbox>
              I agree to the{" "}
              <a target="_blank" href={`${config.emailLinkBase}/statics/tcpa`}>
                TCPA Consent & Privacy policy
              </a>{" "}
              (Optional)
            </Checkbox>
          )}
        </Form.Item>
      </div>
    </fieldset>
  );

  async function confirmUserIdentity(id) {
    dispatch(ShowSpinner(true));
    cognito.Auth = cognito.reconfigure({
      authenticationFlowType: "USER_PASSWORD_AUTH"
    });
    if (flow === "VUA") {
      dispatch(ShowSpinner(false));
      return;
    }
    const res = await cognito.signIn(id, code).catch(e => {
      if (e.code === "NotAuthorizedException")
        e.message = "Link Expired. Create account, log in, or reset your password to continue.";
      dispatch(SetActiveFlows("signIn"));
      dispatch(SetBannerState({ type: "error", text: e.message, show: true }));
      cognito.signOut();
      return <Redirect to="./signIn" />;
    });
    setUser(res);
    cognito.user = res;
    dispatch(ShowSpinner(false));
    return;
  }
};

export default ConfirmAccountFieldset;
