export default {
  CONFIRM_ACCOUNT: "confirmAccount",
  RESET_PASSWORD: "resetPassword",
  VERIFY_CONFIRMATION_CODE: "verify",
  NEW_PASSWORD: "newPassword",
  SIGN_IN: "signIn",
  SIGN_UP: "register",
  ACCOUNT_ACTION_REQUIRED: "accountActionRequired",
  PASSWORD_ACTION_REQUIRED: "resetPassword/actionRequired"
};
