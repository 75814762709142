export const ERRORS = {
  USER_NOT_FOUND: 1000
};

export const EXCEPTION_CODE = {
  USER_NOT_FOUND: "UserNotFoundException",
  INVALID_PARAMETER: "InvalidParameterException",
  NOT_AUTHORIZED: "NotAuthorizedException"
};

export const errorMessageIds = {
  [ERRORS.USER_NOT_FOUND]: "Couldn't find your Domuso account"
};

export const getErrorMessageByCode = code => {
  return errorMessageIds[code];
};
