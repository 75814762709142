import { Form } from "antd";
import React, { useContext, useState } from "react";
import { useContextState } from "../State";
import { SetActiveFlows } from "../../State/actions";
import CognitoFlows from "../../Constants/CognitoFlowState";
import { CognitoContext } from "../Authenticator";
import { TextField, InputAdornment, Input, InputLabel, FormControl } from "@material-ui/core";

const SignInFieldset = props => {
  const { c, getFieldDecorator, history } = props;
  const [, dispatch] = useContextState();
  let cognito = useContext(CognitoContext);
  cognito.signOut();

  let [showPassword, setShowPassword] = useState(false);

  return (
    <fieldset className={"signin-fieldset"}>
      <div className={c.row}>
        <Form.Item colon={false}>
          {getFieldDecorator("email", {
            rules: [{ required: true, message: "Email Address is required" }, { whitespace: false }]
          })(
            <TextField
              label="Email Address"
              onChange={e => {
                if (
                  props.form.getFieldError("email") &&
                  props.form.getFieldError("email")[0] === "Username or password is incorrect"
                ) {
                  props.form.setFields({
                    email: {
                      value: e.currentTarget.value
                    },
                    password: {
                      value: props.form.getFieldValue("password")
                    }
                  });
                }
              }}
            />
          )}
        </Form.Item>
      </div>
      <div className={c.row}>
        <Form.Item colon={false}>
          {getFieldDecorator("password", {
            rules: [{ required: true, message: "Password is required" }, { whitespace: false }]
          })(
            <FormControl>
              <InputLabel htmlFor={`password`}>Password</InputLabel>
              <Input
                onChange={e => {
                  if (
                    props.form.getFieldError("password") &&
                    props.form.getFieldError("password")[0] === "Username or password is incorrect"
                  ) {
                    props.form.setFields({
                      email: {
                        value: props.form.getFieldValue("email")
                      },
                      password: {
                        value: e.currentTarget.value
                      }
                    });
                  }
                }}
                id={`password`}
                type={showPassword ? "text" : "password"}
                endAdornment={
                  <InputAdornment position="end">
                    <span
                      className={`password-visibility`}
                      onClick={() => setShowPassword(!showPassword)}
                    >
                      {showPassword ? "Hide" : "Show"}
                    </span>
                  </InputAdornment>
                }
              />
            </FormControl>
          )}
        </Form.Item>
      </div>
      <a
        className="helpText"
        key="helpText"
        onClick={() => dispatch(SetActiveFlows([CognitoFlows.RESET_PASSWORD, history]))}
      >
        Forgot password?
      </a>
    </fieldset>
  );
};

export default SignInFieldset;
