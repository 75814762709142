const formatPhoneNumber = phone => {
  return phone.replace(/\D/g, "");
};

export const formatCognitoPhoneNumber = phone => {
  const formatted = formatPhoneNumber(phone);
  return `+1${formatted}`;
};

export const trimWhiteSpaces = str => {
  if (typeof str !== "string") {
    return str;
  }
  return str.trim();
};

export const isValidPhoneNumber = phone => {
  if (!phone) return false;
  phone = formatPhoneNumber(phone);
  if (phone.length <= 9) return false;
  const usaRegex = /(\([2-9]\d{2}\)|[2-9]\d{2})-?[2-9]\d{2}-?\d{4}$/;
  return Boolean(phone.match(usaRegex));
};
