import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { BrowserRouter } from "react-router-dom";
import ErrorBoundary from "./Components/ErrorBoundary";
import { Provider as StateProvider } from "./Components/State";
import reducer from "./State/reducer";
import { history } from "./Components/State";
import appConfig from "./config";

const config = {
  storage: {
    name: "state"
  }
};

let initialState = { fetching: false };

try {
  const persisted = window.localStorage.getItem(config.storage.name);
  if (persisted) {
    const state = persisted && JSON.parse(persisted);
    initialState = state || {};
  }
} catch (e) {
  console.error(
    `error accessing [${config.storage.name}] from window.localStorage: ${JSON.stringify(e)}`
  );
}

ReactDOM.render(
  <BrowserRouter history={history}>
    <ErrorBoundary>
      <StateProvider reducer={reducer} initialState={initialState}>
        <App />
      </StateProvider>
    </ErrorBoundary>
  </BrowserRouter>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
