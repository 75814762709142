import React, { createContext, useContext, useReducer } from "react";
import { createBrowserHistory } from "history";

export const history = createBrowserHistory();

export const Context = createContext();

export const Provider = ({ reducer, initialState, children }) => (
  <Context.Provider value={useReducer(reducer, initialState)}>{children}</Context.Provider>
);

export const useContextState = () => useContext(Context);
