import { Form, AutoComplete } from "antd";
import NumberFormat from "react-number-format";
import debounce from "lodash/debounce";
import { SetActiveFlows, SetBannerState } from "../../State/actions";
import { isValidPhoneNumber } from "../../utils";
import CognitoFlowState from "../../Constants/CognitoFlowState";
import { TextField } from "@material-ui/core";
import React, { useState, useCallback, useEffect } from "react";
import config from "../../config";

const RegistrationFieldset = props => {
  const { c, activeProperty = {}, getFieldDecorator, dispatch, history, form } = props;
  if (
    activeProperty &&
    activeProperty.hasOwnProperty("allowSelfSignup") &&
    !activeProperty.allowSelfSignup
  ) {
    dispatch(
      SetBannerState({
        type: "error",
        show: true,
        text: "You must specify a property to register for Domuso"
      })
    );
    dispatch(SetActiveFlows([CognitoFlowState.SIGN_IN, history]));
    return null;
  }

  const [propertyOptions, setPropertyOptions] = useState([]);
  const [propertyId, setPropertyId] = useState(null);

  useEffect(() => {
    if (propertyOptions.length) {
      form.validateFields(["property_id"], { force: true });
    }
  }, [propertyId]);

  const onSearch = async searchText => {
    setPropertyId(null);
    if (!searchText) {
      return setPropertyOptions([]);
    }

    try {
      const resp = await fetch(
        `${config.appService}/property/searchPropertiesAjax?term=${searchText}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json"
          }
        }
      );

      const properties = (await resp.json()).map(prop => {
        return {
          text: prop.label,
          value: prop.id
        };
      });

      setPropertyOptions(properties);
    } catch (e) {
      console.error("Failed to get properties", e);
    }
  };

  const onPropertySelect = value => {
    setPropertyId(value);
  };

  const debouncedOnSearch = useCallback(debounce(onSearch, 500), []);

  return (
    <fieldset className={"register-fieldset"}>
      {!activeProperty && (
        <div className={`${c.row} group`}>
          <Form.Item colon={false}>
            {getFieldDecorator("property_id", {
              validateFirst: true,
              rules: [
                { required: true, message: "Property is required." },
                {
                  validator: async _ => {
                    if (propertyId) {
                      return Promise.resolve();
                    } else {
                      return Promise.reject("Please select a property.");
                    }
                  }
                }
              ]
            })(
              <AutoComplete
                dataSource={propertyOptions}
                onSearch={debouncedOnSearch}
                onSelect={onPropertySelect}
                placeholder="Enter Property Name"
              />
            )}
          </Form.Item>
        </div>
      )}

      <div className={`${c.row} group`}>
        <Form.Item colon={false}>
          {getFieldDecorator("given_name", {
            validateFirst: true,
            rules: [
              { required: true, message: "First name is required." },
              {
                validator: async (_, value) => {
                  return String(value).trim().length === 0
                    ? Promise.reject("First name is required.")
                    : Promise.resolve();
                }
              }
            ]
          })(<TextField label="First Name" />)}
        </Form.Item>
      </div>
      <div className={`${c.row} group`}>
        <Form.Item colon={false}>
          {getFieldDecorator("family_name", {
            validateFirst: true,
            rules: [
              { required: true, message: "Last name is required." },
              {
                validator: async (_, value) => {
                  return String(value).trim().length === 0
                    ? Promise.reject("Last name is required.")
                    : Promise.resolve();
                }
              }
            ]
          })(<TextField label="Last Name" />)}
        </Form.Item>
      </div>
      <div className={`${c.row} group`}>
        <Form.Item colon={false}>
          {getFieldDecorator("email", {
            rules: [
              { required: true, message: "Email is required" },
              {
                type: "email",
                message: "Invalid Email"
              },
              { whitespace: false }
            ]
          })(<TextField label="Email" />)}
        </Form.Item>
      </div>
      <div className={`${c.row} group`}>
        <Form.Item colon={false}>
          {getFieldDecorator("phone_number", {
            rules: [
              { required: true, message: "Phone number is required" },
              {
                validator: async (_, value) => {
                  if (!value || isValidPhoneNumber(value)) {
                    return Promise.resolve();
                  }
                  return Promise.reject("Phone number is invalid.");
                }
              },
              { whitespace: false }
            ]
          })(
            <NumberFormat
              className="phone-field"
              customInput={TextField}
              label="Phone Number"
              renderText={value => <div>{value}</div>}
              format="(###) ###-####"
              mask="_"
            />
          )}
        </Form.Item>
      </div>
    </fieldset>
  );
};

export default RegistrationFieldset;
