import React, { useContext, useEffect, useState } from "react";
import config from "../config";
import Amplify, { Auth } from "aws-amplify";
Amplify.configure({
  Auth: {
    region: "us-east-1",
    userPoolId: config.userPoolId,
    userPoolWebClientId: config.userPoolWebClientId,
    authenticationFlowType: "USER_PASSWORD_AUTH",
    mandatorySignIn: true,
    clientMetadata: { propertyCode: "" }
  }
});

export const CognitoContext = React.createContext(
  Object.assign(Auth, {
    reconfigure: updates => Auth.configure(Object.assign(Auth.configure(), updates, {}))
  })
);
