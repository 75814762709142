import React from "react";
import { Notifier } from "@airbrake/browser";

export default class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false, error: null, info: null };
    this.airbrake = new Notifier({
      projectId: 270821,
      projectKey: "d2d75258d68193ca82d7ae79256c4bd3",
      environment: process.env.NODE_ENV
    });
  }

  componentDidCatch(error, info) {
    this.setState({ hasError: true, error, info });
    this.airbrake.notify({
      error: error,
      params: { info: info }
    });
  }

  render() {
    if (this.state.hasError) {
      return (
        <div style={{ padding: "15px", textAlign: "center" }}>
          <h1>
            Something went wrong :(
            <br />
            Please Refresh.
          </h1>
          <h3>Details:</h3>
          <pre style={{ whiteSpace: "pre-wrap" }}>Error: {JSON.stringify(this.state.error)}</pre>
          <pre style={{ whiteSpace: "pre-wrap" }}>{JSON.stringify(this.state.info)}</pre>
        </div>
      );
    }
    return this.props.children;
  }
}
