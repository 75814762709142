import React from "react";
import injectSheet from "react-jss";
import { useContextState } from "../Components/State";
import { SetBannerState } from "../State/actions";
import { TransitionGroup } from "react-transition-group";

function Banner(props) {
  const [bannerState, dispatch] = useContextState();
  const { classes: c, show, type, element, text: txt = "" } = props;
  let text = normalizeErrMsg(txt);
  const closeBanner = () => dispatch(SetBannerState({ ...bannerState, show: false }));
  return (
    <div className={`${c.Banner} ${show ? "show " : " "} ${type}`}>
      <img
        src={`/images/${type === "error" ? "mark-error.svg" : "mark-success.svg"}`}
        className={`mark-image mark-image--${type}`}
      />
      <span className="banner-text">{element ? element : <span>{text}</span>}</span>
      <div onClick={closeBanner} className="close">
        <span className="close-text">Close</span> <span className="close-icon">&#215;</span>
      </div>
    </div>
  );

  function normalizeErrMsg(errText = "") {
    try {
      let textArr = errText.split("failed with error ");
      return textArr[1] || errText;
    } catch (e) {
      console.error("Failed to normalize error message", e);
      return errText;
    }
  }
}

const css = {
  Banner: {
    position: "relative",
    fontWeight: "400",
    display: "none",
    fontSize: "14px",
    alignSelf: "center",
    padding: "10px 18px",
    borderRadius: "4px",
    animation: "3s ease-in 1",
    "animation-delay": "0s",
    "animation-direction": "normal",
    "animation-fill-mode": "none",
    "animation-iteration-count": 1,
    "animation-timing-function": "ease",
    "& .banner-text": {
      marginLeft: "32px",
      wordBreak: "break-word",
      textAlign: "center"
    },
    "&.show": { display: "flex", height: "auto" },
    "&.error": {
      color: "#DA1C0E",
      background:
        "linear-gradient(0deg, rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.9)), #F44336"
    },
    "&.success": {
      color: "#1E4620",
      background:
        "linear-gradient(0deg, rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.9)), #4CAF50"
    },
    "& .close": {
      marginLeft: "12px",
      cursor: "pointer",
      fontWeight: 600,
      position: "relative",
      float: "right",
      "& .close-text": {
        marginRight: "20px"
      },
      "& .close-icon": {
        fontSize: "28px",
        marginLeft: "4px",
        lineHeight: "0",
        position: "absolute",
        top: "10px",
        right: "0"
      }
    },
    "& .mark-image": {
      position: "absolute",
      top: "8px"
    },
    "& .mark-image--success": {
      top: "10px"
    }
  }
};

export default injectSheet(css)(Banner);
