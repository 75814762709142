export const ActionTypes = {
  SET_ACTIVE_FLOW: "SET_ACTIVE_FLOW",
  SET_BANNER_STATE: "SET_BANNER_STATE",
  SET_COGNITO_DETAILS: "SET_COGNITO_DETAILS",
  SET_SPINNER_STATE: "SET_SPINNER_STATE"
};

export const SetActiveFlows = payload => {
  return {
    type: ActionTypes.SET_ACTIVE_FLOW,
    payload
  };
};

export const SetCognitoDetails = payload => {
  return {
    type: ActionTypes.SET_COGNITO_DETAILS,
    payload
  };
};

export const SetBannerState = payload => ({ type: ActionTypes.SET_BANNER_STATE, payload });

export const ShowSpinner = payload => ({ type: ActionTypes.SET_SPINNER_STATE, payload });

export default {
  SetActiveFlows,
  SetBannerState,
  SetCognitoDetails,
  ShowSpinner
};
