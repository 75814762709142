import React from "react";
import ActionRequiredNotice_Account from "./ActionRequired_Account";
import ConfirmAccountFieldset from "./ConfirmAccount";
import NewPasswordFieldset from "./NewPassword";
import RegistrationFieldset from "./Register";
import ResetPasswordFieldset from "./ResetPassword";
import SignInFieldset from "./SignIn";

export default {
  accountActionRequired: props => <ActionRequiredNotice_Account {...props} />,
  confirmAccount: props => <ConfirmAccountFieldset {...props} />,
  newPassword: props => <NewPasswordFieldset {...props} />,
  register: props => <RegistrationFieldset {...props} />,
  resetPassword: props => <ResetPasswordFieldset {...props} />,
  signIn: props => <SignInFieldset {...props} />
};
