import React from "react";
import { Form } from "antd";
import { TextField } from "@material-ui/core";

const ResetPasswordFieldset = props => {
  const { c, getFieldDecorator } = props;

  return (
    <fieldset>
      <div className={c.row}>
        <Form.Item colon={false}>
          {getFieldDecorator("email", {
            rules: [
              { required: true, message: "Email or Username is required" },
              { whitespace: false }
            ]
          })(<TextField label="Email or Username" />)}
        </Form.Item>
      </div>
    </fieldset>
  );
};

export default ResetPasswordFieldset;
