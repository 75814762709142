import React from "react";
import { useContextState } from "../State";
const isValidEmail = e => require("email-validator").validate(e);

const ActionRequiredNotice_Account = props => {
  const { c } = props;
  const [{ cognito }] = useContextState();
  let email = cognito ? cognito.email : "";

  return (
    <summary className={"action-required"}>
      <p style={{ whiteSpace: "break-word" }}>
        {getIntro(email)}
        Click the link in the email to verify your email address and set your password.
      </p>
    </summary>
  );
};

const getIntro = email => {
  if (email) {
    if (isValidEmail(email)) {
      return (
        <span>
          A Confirmation Email has been sent to <strong>{email}</strong>.{"\u00A0"}
        </span>
      );
    } else {
      return `Confirmation Email sent.\u00A0`;
    }
  } else {
    return "";
  }
};

export default ActionRequiredNotice_Account;
